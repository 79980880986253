<script setup lang="ts">
const { currentMenu } = storeToRefs(useCurrentMenu())

const emits = defineEmits([
  'click:logo',
])

const clickLogo = () => {
  currentMenu.value = '/'
  navigateTo('/')
  emits('click:logo')
}
</script>

<template>
  <NuxtImg
    class="cursor-pointer"
    :src="'/image/goodnodes-logo.webp'"
    sizes="sm:160px md:262px"
    preload
    @click="clickLogo()"
  />
</template>
