<script setup lang="ts">
const { currentMenu } = storeToRefs(useCurrentMenu())

const activateMenu = computed(() => (menuPath: string) => {
  return currentMenu.value === menuPath
})

const clickMenu = (route: string) => {
  currentMenu.value = route
  navigateTo(route)
}
</script>

<template>
  <div class="flex items-center gap-x-[68px]">
    <NuxtLink
      to="/"
      :class="[
        'w-fit p-1 text-sm cursor-pointer transition ease-in-out hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/policy') },
      ]"
      @click="clickMenu('/policy')"
    >
      {{ $t('menu.policy') }}
    </NuxtLink>
    <NuxtLink
      to="/service"
      :class="[
        'w-fit p-1 text-sm cursor-pointer transition ease-in-out hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/faq') },
      ]"
      @click="clickMenu('/faq')"
    >
      {{ $t('menu.faq') }}
    </NuxtLink>
    <NuxtLink
      to="/recruit"
      :class="[
        'w-fit p-1 text-sm cursor-pointer transition ease-in-out hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/press') },
      ]"
      @click="clickMenu('/press')"
    >
      {{ $t('menu.press') }}
    </NuxtLink>
  </div>
</template>
