<script setup lang="ts">
const { width } = useWindowSize()

withDefaults(
  defineProps<{
    isShow?: boolean
  }>(),
  {
    isShow: false,
  },
)

const contactMail = 'contact@goodnodes.biz'

const sendContactMail = () => {
  navigateTo('mailto:contact@goodnodes.biz', { external: true })
}
</script>

<template>
  <div class="w-dvw h-fit max-h-[418px] lg:max-h-[300px] lg:max-w-[1302px] flex flex-col gap-y-10 border-t border-neutral-100/80 py-[70px] px-10">
    <div
      v-if="isShow"
      class="flex justify-between"
    >
      <MenuFooter />
      <MenuFooterSocial />
    </div>
    <div class="flex flex-col gap-y-2.5">
      <div>
        <p
          :class="[
            'break-keep text-neutral-400',
            { 'text-xs': width < 400 },
            { 'text-sm': width > 400 },
          ]"
        >
          {{ $t('footer.location') }}
        </p>
        <p
          :class="[
            'break-keep text-neutral-400 cursor-pointer transition ease-in-out hover:text-neutral-300/80 dark:hover:text-neutral-700/80',
            { 'text-xs': width < 400 },
            { 'text-sm': width > 400 },
          ]"
          @click="sendContactMail"
        >
          {{ $t('footer.contact', { mail: contactMail }) }}
        </p>
      </div>
      <p
        :class="[
          'break-keep text-neutral-400',
          { 'text-xs': width < 400 },
          { 'text-sm': width > 400 },
        ]"
      >
        {{ $t('footer.copyright') }}
      </p>
    </div>
  </div>
</template>
