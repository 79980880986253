<script setup lang="ts">
const { currentMenu } = storeToRefs(useCurrentMenu())

withDefaults(
  defineProps<{
    isDesktop?: boolean
  }>(),
  {
    isDesktop: false,
  },
)

const emits = defineEmits([
  'click:menu',
])

const activateMenu = computed(() => (menuPath: string) => {
  return currentMenu.value === menuPath
})

const clickMenu = (route: string) => {
  currentMenu.value = route
  emits('click:menu')
  navigateTo(route)
}
</script>

<template>
  <div
    :class="[
      { 'w-full flex gap-x-4': isDesktop },
      { 'flex flex-col gap-y-4': !isDesktop },
    ]"
  >
    <NuxtLink
      :class="[
        'w-fit p-2.5 text-base font-semibold cursor-pointer transition ease-in-out hover:font-semibold hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/') },
      ]"
      exact-active-class="border-0 ring-0"
      prefetched-class="border-0 ring-0"
      active-class="border-0 ring-0"
      @click="clickMenu('/')"
    >
      {{ $t('menu.home') }}
    </NuxtLink>
    <NuxtLink
      :class="[
        'w-fit p-2.5 text-base font-semibold cursor-pointer transition ease-in-out hover:font-semibold hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/service') },
      ]"
      @click="clickMenu('/service')"
    >
      {{ $t('menu.service') }}
    </NuxtLink>
    <NuxtLink
      :class="[
        'w-fit p-2.5 text-base font-semibold cursor-pointer transition ease-in-out hover:font-semibold hover:rounded-lg hover:text-sky-600/80 dark:hover:text-sky-400/80',
        { 'text-sky-600/80 dark:text-sky-400/80': activateMenu('/recruit') },
      ]"
      @click="clickMenu('/recruit')"
    >
      {{ $t('menu.recruit') }}
    </NuxtLink>
  </div>
</template>
