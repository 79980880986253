<script setup lang="ts">
const socialActivate = ref('')

const clickSocial = (social: string) => {
  socialActivate.value = social

  // 클릭시, 네비게이션 로직 넣어야 함
}
</script>

<template>
  <div class="flex items-center gap-x-2">
    <div
      :class="[
        'p-2 cursor-pointer transition ease-in-out hover:rounded-lg hover:text-neutral-300/80 dark:hover:text-neutral-700/80',
        { 'text-neutral-400/80 dark:text-neutral-600/80': socialActivate === 'youtube' },
      ]"
      @click="clickSocial('youtube')"
    >
      <Icon
        name="i-ri-youtube-line"
        class="w-6 h-6"
      />
    </div>
  </div>
</template>
