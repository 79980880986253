<script setup lang="ts">
const { locale, setLocale } = useLocale()

withDefaults(
  defineProps<{
    isShow?: boolean
    mobileSize?: boolean
  }>(),
  {
    isShow: false,
    mobileSize: false,
  },
)
</script>

<template>
  <div
    v-if="isShow"
    class="flex items-center gap-x-2"
  >
    <div
      :class="[
        'break-keep px-3 py-2 cursor-pointer transition ease-in-out hover:rounded-lg hover:text-neutral-300/80 dark:hover:text-neutral-700/80',
        { 'text-neutral-300/80 dark:text-neutral-700/80': locale === 'ko' },
        { 'text-sm': mobileSize },
      ]"
      @click="setLocale('ko')"
    >
      {{ $t('localeMenu.ko') }}
    </div>
    <div class="border-l border-neutral-300/80 h-4" />
    <div
      :class="[
        'break-keep px-3 py-2 cursor-pointer transition ease-in-out hover:rounded-lg hover:text-neutral-300/80 dark:hover:text-neutral-700/80',
        { 'text-neutral-300/80 dark:text-neutral-700/80': locale === 'en' },
        { 'text-sm': mobileSize },
      ]"
      @click="setLocale('en')"
    >
      {{ $t('localeMenu.en') }}
    </div>
  </div>
</template>
