<script setup lang="ts">
const { width } = useWindowSize()

const slideMenuTrigger = ref(false)

watchEffect(() => {
  if (width.value > 400) {
    slideMenuTrigger.value = false
  }
})
</script>

<template>
  <div class="w-dvw h-16 flex justify-between items-center py-2 px-4">
    <ALogo />
    <div
      class="flex items-center justify-center p-2 cursor-pointer"
      @click="() => slideMenuTrigger = true"
    >
      <Icon
        name="i-ri-menu-2-line"
        class="cursor-pointer w-9 h-9"
      />
    </div>
    <GBSlideover
      v-model="slideMenuTrigger"
      label="slide-menu"
      :ui="{ width: 'w-screen max-w-full' }"
    >
      <div class="flex flex-col gap-y-7">
        <div class="flex justify-between items-center px-4 py-1">
          <ALogo @click:logo="() => slideMenuTrigger = false" />
          <div
            class="p-2 cursor-pointer"
            @click="() => slideMenuTrigger = false"
          >
            <Icon
              name="i-ri-close-line"
              class="w-9 h-9"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-4 px-4">
          <MenuHeader @click:menu="() => slideMenuTrigger = false" />
          <ALanguageChange mobile-size />
        </div>
      </div>
    </GBSlideover>
  </div>
</template>
