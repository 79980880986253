<script setup lang="ts">
const { width } = useWindowSize()

const mobileMenuTrigger = ref(false)
</script>

<template>
  <div class="relative flex flex-col items-center">
    <HeaderMobile
      v-show="width < 820"
      @open-mobile-menu="() => mobileMenuTrigger = true"
    />
    <HeaderDesktop v-show="width > 820" />
    <slot />
    <CommonFooter />
    <MobileMenuDialog
      :dialog-trigger="mobileMenuTrigger"
      @close-dialog="() => mobileMenuTrigger = false"
    />
  </div>
</template>
